import React from "react"
import { IInputData } from "../../base/services/types/admin"
import { TransitionProps } from '@mui/material/transitions'
import { Slide } from '@mui/material'


const setDefaultValues = (
    fieldsData: IInputData,
    setFormValues: (defaultValues: { [name: string]: IInputData["value"] }) => void
) => {
    var defaultValues: { [name: string] : IInputData["value"] } = {}

    Object.entries(fieldsData).forEach(([fieldName, fieldProps]) => {
        defaultValues[fieldName] = (fieldProps.value || (fieldProps.value === 0)) ? fieldProps.value : null
    })

    setFormValues(defaultValues)

    return defaultValues
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />
})

const parseDefaultDiagnoses = (diagnoses?: string[] | string) => {
    if (!diagnoses) {
        return []
    } else if (diagnoses && Array.isArray(diagnoses)) {
        return diagnoses
    } else {
        return diagnoses?.split("\n")
    }
}


export {
    setDefaultValues,
    Transition,
    parseDefaultDiagnoses
}